import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import NavBar from "../../components/NavBar/NavBar";
import MediumBinComponent from "../../components/MediumBinComponent/MediumBinComponent"
import { Helmet } from "react-helmet";

function MediumBin(props) {
  return (
    <>
      <Helmet>
        <title>200 Gallon | Sigma Industries</title>
      </Helmet>
      <NavBar showLogo={true} navColour="transparent"/>
      <PageHeader headline="The Vault - 200 Gallon" />
      <MediumBinComponent />
      <ContactForm id="contactForm" />
    </>
  );
}

export default MediumBin;
