import React, { useEffect, useRef  } from "react";
import OurProducts from "../components/OurProducts/OurProducts";
import ContactForm from "../components/ContactForm/ContactForm";
import Hero from "../components/Hero/Hero";
import AboutUs from "../components/AboutUs/AboutUs";
import Values from "../components/Values/Values"
import SpacerImg from "../components/SpacerImg/SpacerImg";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import WhyChooseSigma from "../components/WhyChooseSigma/WhyChooseSigma";

function HomePage() {

  const scollToRef = useRef();

  
  const metaDescription =
  "Our used cooking oil bins offer an environmentally conscious and efficient way to dispose of your kitchen's waste oil.";
  const title = "Sigma Industries | Cooking Oil Containers | Calgary, AB";
  

  const navigate = useNavigate();
  const handleClickScroll = () => {
    const element = document.getElementById('products');
    if (element) {
      console.log("CLicked")
      navigate('/');
      element.scrollIntoView({ behavior: 'smooth' });

    }
  };


  return (
    <main>
      <Helmet>
        <meta name="image" content={"https://i.postimg.cc/BQf7Dm9g/meta-img.jpg"} />
        <meta name="description" content={metaDescription} />
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={"https://i.postimg.cc/BQf7Dm9g/meta-img.jpg"} />
        <meta property="og:url" content="https://sigmaind.ca/" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content={"https://i.postimg.cc/BQf7Dm9g/meta-img.jpg"} />
        <meta property="twitter:url" content="https://sigmaind.ca/" />
      </Helmet>
      <Hero onClick={handleClickScroll} />
      <AboutUs id="about" />
      <OurProducts products="products" ref={scollToRef} />
      <SpacerImg />
      <WhyChooseSigma />
      <Values/>
      <ContactForm id="contactForm" />
    </main>
  );
}

export default HomePage;
