import React, { useEffect, useState } from "react";
import "./pageHeader.scss";

function PageHeader({ headline }) {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150);
    });
  }, []);
  return (
    <>
      <div className={scroll ? "page-header-scrolled" : "page-header"}>
        <div>
          <h1 className="main-headline white">{headline}</h1>
        </div>
      </div>
    </>
  );
}

export default PageHeader;
