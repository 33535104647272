import React, { useState, useEffect } from "react";
import "./contactForm.scss";
import axios from "axios";

import ImageOne from "../../assets/images/1.jpg";
import ImageTwo from "../../assets/images/2.jpg";
import ImageThree from "../../assets/images/3.jpg";

import Radium, { StyleRoot } from "radium";

export default function ContactForm({ id }) {
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const [photo, setPhoto] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      change();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [photo]);

  const change = () => {
    if (photo === 3) {
      setPhoto(1);
      return;
    }

    setPhoto((prev) => prev + 1);
  };

  const returnPhotoURL = () => {
    switch (photo) {
      case 1:
        return ImageOne;
      case 2:
        return ImageTwo;
      case 3:
        return ImageThree;
      default:
        return ImageOne;
    }
  };


  const style = {
    backgroundImage: `url(${returnPhotoURL()})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // Adding media query..
    '@media (max-width: 450px)': {
      background: "#003469",
      backgroundImage: "none",
    },
  };

  async function sendEmail(e) {
    e.preventDefault();
    await axios
      .post(`https://site-api.maverickdownhole.com/form/sendSigmaEmail`, {
        name: document.getElementById("name").value,
        phone: document.getElementById("phone").value,
        email: document.getElementById("email").value,
        message: document.getElementById("message").value,
      })
      .then((res) => {
        console.log(res);

        document.getElementById("name").value = "";
        document.getElementById("phone").value = "";
        document.getElementById("email").value = "";
        document.getElementById("message").value = "";
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
      });
  }

  return (
      <div
        className="container-form-contianer"
        id={id}
        //style={style}
      >
        {/* <div className="inner-container">
          <div>
            <h1 className="contact-header">Contact Us</h1>
          </div>
          <div className="form">
            <form>
              <label for="name">Full Name</label>
              <input id="name" type="text" required className="form-input" />
              <label for="phone" type="number">
                Phone Number
              </label>
              <input id="phone" required className="form-input" />
              <label for="email">Email</label>
              <input id="email" type="email" required className="form-input" />
              <label for="message">Message</label>
              <input id="message" type="message" className="form-input" />
              <div>
                <button type="submit" className="blue-btn" onClick={sendEmail}>
                  Submit
                </button>
                {success && (
                  <p className="success">Thanks! Your message has been sent.</p>
                )}
                {failed && (
                  <p className="failed">
                    Sorry, an error occured. Please try again.
                  </p>
                )}
              </div>
            </form>
          </div>
        </div> */}
      </div>
  );
}
