import React from "react";
import "./Values.scss";
import BinImg from "../../assets/images/about.jpg";

import Customer from "../../assets/icons/Customer.svg"
import Safety from "../../assets/icons/Safety.svg"
import Sustainability from "../../assets/icons/Sustainability.svg"
import Quality from "../../assets/icons/Quality.svg"

function Values(props) {


  const ourValues = [
    {
      icon: Customer,
      headline: "Customer-Centric Approach",
      text: "Our customers are at the heart of everything we do. We believe in building strong, long lasting relationships by going the extra mile to meet your needs and exceed your expectations.",
    },
    {
      icon: Quality,
      headline: "Quality Assurance",
      text: "Quality is non-negotiable at SIGMA Industries. We take pride in our state of the art manufacturing process and stringent quality control measures to ensure that our products meet the highest standards.",
    },
    {
      icon: Safety,
      headline: "Safety",
      text: "Safety is a critical value for our company. We are dedicated to maintaining a safe working environment for all of our employees while at the same time producing steel products that meet the highest safety standards.",
    },
    {
      icon: Sustainability,
      headline: "Sustainability",
      text: "We are passionate about environmental responsibility. By supporting the collection and recycling of used cooking oil, we contribute to a greener, more sustainable future for our planet.",
    },
  ]

  return (
    <div className="values-container-outer">
      <div className="ourValuesSection">
        <h3 className="subhead text-centre">
          Our <span className="green-text">Values</span>
        </h3>
        <div className="ourValuesGrid">
        {ourValues.map(function (data) {
          return (
            <div>
              <img src={data.icon} alt={data.headline} className="ourValuesIcon" />
              <h4 className="small-subhead text-centre">{data.headline}</h4>
              <p className="body-text text-centre">{data.text}</p>
            </div>
          );
        })}
        </div>
      </div>
    </div>
  );
}

export default Values;
