import React from "react";
import MainImg from "../../assets/images/new-images/LB100.png";

function LargeBinComponent(props) {
  return (
    <div className="page-component">
      <div className="imgContainer">
        {/* <img src={MainImg} alt="Bin" className="large-img" /> */}
        <iframe src="https://app.vectary.com/p/7G5IiPdtNdNRcDnD6hakmH"></iframe>
      </div>
      <div>
        <h2 className="subhead">Features</h2>
        <ul className="accessories-list">
          <li>12 Gauge steel bin body</li>
          <li>14 Gauge steel lid and hatch</li>
          <li>Lifting studs</li>
          <li>
            Versatile lid with the ability of being secured at either 110
            degrees or 270 degrees for easy access.
          </li>
          <li>Brackets for installing dump pins</li>
          <li>Handles to safely open and close the lid and hatch</li>
          <li>Hatch access for disposing of UCO</li>
          <li>
            Screen manufactured from 5/16” wire rod, spacing is 2” on centre,
            available in sliding or static configurations
          </li>
          <li>
            Single point drop in pin lock housing located centre of the bin
            combined with lockable hatch{" "}
          </li>
          <li>Fork lift slots for easy maneuverability</li>
          <li>Powder coating, custom client logo and labeling</li>
        </ul>

        <div className="spacer"></div>

        <h4 className="small-subhead">Optional Features</h4>
        <ul className="optional-list">
          <li>Dump pins </li>
          <li>Bin dollies for easy onsite movability</li>
          <li>Custom screens are available as per client requests</li>
        </ul>
        <div className="spacer"></div>

        <p className="body-text">
          The screen is located below the level of the bin body to reduce the
          chances of overfilling the bin and causing a spill. This detailed
          feature will also keep your bins looking clean over time. 
          <br /><br />
          Our bins are designed to be secured but also provide faster access to the collection company in order to increase UCO collection efficiency
        </p>
      </div>
    </div>
  );
}

export default LargeBinComponent;
