import React, { useState, useEffect } from "react";
import "./footer.scss";
import FooterLogo from "../../assets/logos/SigmaLogo_REV-02.svg";
import axios from "axios";

function Footer({ id }) {
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  async function sendEmail(e) {
    e.preventDefault();
    await axios
      .post(`https://site-api.maverickdownhole.com/form/sendSigmaEmail`, {
        name: document.getElementById("name").value,
        phone: document.getElementById("phone").value,
        email: document.getElementById("email").value,
        message: document.getElementById("message").value,
      })
      .then((res) => {
        console.log(res);

        document.getElementById("name").value = "";
        document.getElementById("phone").value = "";
        document.getElementById("email").value = "";
        document.getElementById("message").value = "";
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
      });
  }

  return (
    <footer>
      <div className="footer-inner-container">
        <div>
          <img src={FooterLogo} className="footer-logo" />
          <br /> <br />
          <h4 className="small-subhead white">Get in Touch</h4>
          <a href="mailto:ales@sigmaind.ca">
            <p className="footer-info">sales@sigmaind.ca</p>
          </a>
          <address className="footer-info">
            Bay 145, 79 Freeport Cres. NE <br /> Calgary, AB
          </address>
        </div>
        <div>
          <div
            className="container-form-contianer"
            id={id}
            //style={style}
          >
            <div className="inner-container">
              <div>
                <h1 className="contact-header">Contact Us</h1>
              </div>
              <div className="form">
                <form>
                  <label for="name">Full Name</label>
                  <input
                    id="name"
                    type="text"
                    required
                    className="form-input"
                  />
                  <label for="phone" type="number">
                    Phone Number
                  </label>
                  <input id="phone" required className="form-input" />
                  <label for="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    required
                    className="form-input"
                  />
                  <label for="message">Message</label>
                  <input id="message" type="message" className="form-input" />
                  <div>
                    <button
                      type="submit"
                      className="blue-btn"
                      onClick={sendEmail}
                    >
                      Submit
                    </button>
                    {success && (
                      <p className="success">
                        Thanks! Your message has been sent.
                      </p>
                    )}
                    {failed && (
                      <p className="failed">
                        Sorry, an error occured. Please try again.
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
