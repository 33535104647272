import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import NavBar from "../../components/NavBar/NavBar";
import LargeBinSideComponent from "../../components/LargeBinSideComponent/LargeBinSideComponent";
import { Helmet } from "react-helmet";

function LargeBinSide(props) {
  return (
    <>
      <Helmet>
        <title>300 Gallon Side Open | Sigma Industries</title>
      </Helmet>
      <NavBar showLogo={true} navColour="transparent"/>
      <PageHeader headline="The Vault - 300 Gallon (Side Open)" />
      <LargeBinSideComponent />
      <ContactForm id="contactForm" />
    </>
  );
}

export default LargeBinSide;
