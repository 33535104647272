import React from "react";
import "./hero.scss";
import HeroLogo from "../../assets/logos/SigmaLogo_RBG-01.svg";
import NavBar from "../NavBar/NavBar";
import MobileHero from "./MobileHero";
import { Carousel } from "antd";

import ImageOne from "../../assets/images/hero/One.jpeg";
import ImageTwo from "../../assets/images/hero/Two.jpeg";
import ImageThree from "../../assets/images/hero/Three.jpeg";

export default function Hero({ onClick }) {
  return (
    <>
      <MobileHero />
      <NavBar showLogo={true} onClick={onClick} />
      {/* <div className="hero-container"> */}
      <div className="carouselContainer">
        <div className="heroText">
          <div className="heroTextBkg">
            <h2 className="main-headline textOutline">
              Your partners in <br />
              used cooking oil
              <br /> storage solutions
            </h2>
          </div>
        </div>
        <Carousel autoplay className="heroCarousel" autoplaySpeed={5000}>
          <div className="slide">
            <img src={ImageOne}/>
          </div>
          <div className="slide">
            <img src={ImageTwo} />
          </div>
          <div className="slide">
            <img src={ImageThree} />
          </div>
        </Carousel>
      </div>
    </>
  );
}
