import React, { useEffect, useRef  } from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import NavBar from "../../components/NavBar/NavBar";
import LargeBinComponent from "../../components/LargeBinComponent/LargeBinComponent";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

function LargeBin(props) {

  const scollToRef = useRef();

  const navigate = useNavigate();
  const handleClickScroll = () => {
    navigate('/');
    //scollToRef.current.scrollIntoView()
  };

  return (
    <>
      <Helmet>
        <title>300 Gallon | Sigma Industries</title>
      </Helmet>

      <NavBar showLogo={true} onClick={handleClickScroll} navColour="transparent"/>
      <PageHeader headline="The Vault - 300 Gallon" />
      <LargeBinComponent />
      <ContactForm id="contactForm" />
    </>
  );
}

export default LargeBin;
