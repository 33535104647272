import React from 'react';
import "./spacerImg.scss";

function SpacerImg(props) {
    return (
        <div className='spacer-img'>
            <h2 className="main-headline white">Stocking Manufacturer</h2>
            <h4 className='green-text subhead'>with fast lead times</h4>
        </div>
    )
}

export default SpacerImg