import React, { useEffect, useState } from "react";
import "./NavBar.scss";
import Logo from "../../assets/logos/SigmaLogo_RBG-01.svg";
import MobileLogo from "../../assets/logos/SigmaLogo_RBG_BLACK-01.svg";

import { Link } from "react-router-dom";
import { Menu, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { HashLink } from "react-router-hash-link";


const blackNav = `rgba(0, 0, 0, 0.9)`;

function NavBar({ showLogo, onClick, navColour =  blackNav}) {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150);
    });
  }, []);

  const [showMenu, setShowMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  return (
    <nav className={scroll ? "navScrolled" : "nav"} style={{backgroundColor: navColour}}>
      <div className="navInnerContainer">
        <div className="logo-container">
          {showLogo && (
            <Link to="/">
              <img
                src={`${scroll ? Logo : Logo}`}
                alt="Logo"
                className="desktopLogo"
              />
              <img src={Logo} alt="Logo" className="mobileLogo" />
            </Link>
          )}
        </div>
        <div>
          <HashLink to="/#about">
            <p className={scroll ? "nav-link-scrolled" : "nav-link"}>About Us</p>
          </HashLink>
          <HashLink to="/#products">
            <p
              className={scroll ? "nav-link-scrolled" : "nav-link"}
              // onClick={onClick}
            >
              Our Products
            </p>
          </HashLink>
          {/* <Menu
            onClick={onMenuClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          /> */}

          <HashLink to="#contactForm">
            <p className={scroll ? "nav-link-scrolled" : "nav-link"}>Contact Us</p>
          </HashLink>

          <div className="mobileMenu" onClick={showDrawer}>
            <MenuOutlined style={{ color: "white" }} />
          </div>

          <Drawer
            title={
              <img src={MobileLogo} style={{ maxWidth: "300px" }} alt="Logo" />
            }
            placement="left"
            onClose={onClose}
            open={open}
            width="75%"
          >
            <Link to="/large-bin">
              <p className="Mobile-link">300 Gallon</p>
            </Link>
            <Link to="/large-bin-side">
              <p className="Mobile-link">300 Gallon Side</p>
            </Link>
            <Link to="/medium-bin">
              <p className="Mobile-link">200 Gallon</p>
            </Link>
            <Link to="/medium-bin-side">
              <p className="Mobile-link">200 Gallon Side</p>
            </Link>
            <Link to="/small-bin">
              <p className="Mobile-link">100 Gallon</p>
            </Link>
          </Drawer>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
