import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import NavBar from "../../components/NavBar/NavBar";
import SmallBinComponent from "../../components/SmallBinComponent/SmallBinComponent"
import { Helmet } from "react-helmet";


function SmallBin(props) {
  return (
    <>
      <Helmet>
        <title>100 Gallon | Sigma Industries</title>
      </Helmet>
      <NavBar showLogo={true} navColour="transparent"/>
      <PageHeader headline="The Vault - 100 Gallon" />
      <SmallBinComponent />
      <ContactForm id="contactForm" />
    </>
  );
}

export default SmallBin;
