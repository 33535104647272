import React from "react";
import "./hero.scss";
import { Carousel } from "antd";

import ImageOne from "../../assets/images/hero/One.jpeg";
import ImageTwo from "../../assets/images/hero/Two.jpeg";
import ImageThree from "../../assets/images/hero/Three.jpeg";

function MobileHero(props) {
  return (
    <div className="mobileHero">
      <div className="mobileHeroText">
        <h2 className="main-headline white">
          Your partners in <br />
          used cooking oil
          <br /> storage solutions
        </h2>
      </div>
      <div className="mobileCarousel">
        <Carousel autoplay className="heroCarousel" autoplaySpeed={5000}>
          <div className="slide">
            <img src={ImageOne} />
          </div>
          <div className="slide">
            <img src={ImageTwo} />
          </div>
          <div className="slide">
            <img src={ImageThree} />
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default MobileHero;
