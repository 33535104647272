import React from "react";
import "./ourProducts.scss";
import { Link } from "react-router-dom";

import LargeSide from "../../assets/images/new-images/LB100 Side Open.png";
import MediumSide from "../../assets/images/new-images/MB100 Side Open.png";

import Large from "../../assets/images/new-images/LB100.png";
import Medium from "../../assets/images/new-images/MB100.png";

import Small from "../../assets/images/new-images/SB100.png";

import Construction from "../../assets/icons/Construction.svg";
import Eco from "../../assets/icons/Eco.svg";
import Local from "../../assets/icons/Local.svg";
import Odor from "../../assets/icons/Odor.svg";
import Safe from "../../assets/icons/Safe.svg";
import Sizing from "../../assets/icons/Sizing.svg";
import Brochure from "../../assets/pdfs/SIGMA Brochure.pdf";

export default function OurProducts({ products, ref }) {
  const listItems = [
    {
      title: "Safe & Secure",
      img: Safe,
    },
    {
      title: "Eco-Friendly Disposal",
      img: Eco,
    },
    {
      title: "Convenient Sizing",
      img: Sizing,
    },
    {
      title: "Durable Construction",
      img: Construction,
    },
    {
      title: "Local Partnerships",
      img: Local,
    },
  ];

  const bins = [
    {
      title: "The Vault - 300 Gallon",
      details: "",
      link: "/large-bin",
      img: Large,
    },
    {
      title: "The Vault - 300 Gallon (Side Open)",
      details: "",
      link: "/large-bin-side",
      img: LargeSide,
    },
    {
      title: "The Vault - 100 Gallon",
      details: "",
      link: "/small-bin",
      img: Small,
    },
    {
      title: "The Vault - 200 Gallon",
      details: "",
      link: "/medium-bin",
      img: Medium,
    },
    {
      title: "The Vault - 200 Gallon (Side Open)",
      details: "",
      link: "/medium-bin-side",
      img: MediumSide,
    },
  ];

  return (
    <div className="our-products-container" id={products} ref={ref}>
      <div className="text-container">
        <h2 className="subhead text-centre">
          Our <span className="green-text">Products</span>
        </h2>
      </div>

      <div className="card-container">
        {bins.map(function (data) {
          return (
            <div className="product-card">
              <h4 className="small-subhead">{data.title}</h4>
              <p className="body-text">{data.details}</p>
              <img src={data.img} alt={data.title} />
              <div className="button-container-product-card">
                <Link to={data.link}>
                  <button className="product-btn blue-btn">See Details</button>
                </Link>
                <a
                  href={Brochure}
                  download="Sigma Industries Brochure"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="product-btn blue-btn">
                    Download Brochure
                  </button>
                </a>
              </div>
            </div>
          );
        })}
      </div>

      {/* <div className="icons-grid">
        {listItems.map(function (data) {
          return (
            <div>
              <img src={data.img} alt={data.title} className="icon" />
              <h4 className="small-subhead text-centre">{data.title}</h4>
            </div>
          );
        })}
      </div> */}
    </div>
  );
}
