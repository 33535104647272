import logo from "./logo.svg";
import "./App.scss";
import HomePage from "./pages/HomePage";
import React from "react";
import LargeBin from "./pages/Products/LargeBin";
import LargeBinSide from "./pages/Products/LargeBinSide";
import MediumBin from "./pages/Products/MediumBin";
import MediumBinSide from "./pages/Products/MediumBinSide";
import SmallBin from "./pages/Products/SmallBin";
import Radium, { StyleRoot } from "radium";

import { Routes, Route, Link, useParams } from "react-router-dom";

function App() {
  return (
    <StyleRoot>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/large-bin" element={<LargeBin />} />
          <Route path="/large-bin-side" element={<LargeBinSide />} />
          <Route path="/medium-bin" element={<MediumBin />} />
          <Route path="/medium-bin-side" element={<MediumBinSide />} />
          <Route path="/small-bin" element={<SmallBin />} />

          <Route path="*" element={<>PAGE NOT FOUND</>} />
        </Routes>
      </div>
    </StyleRoot>
  );
}

export default App;
