import React from "react";
// import "./largeBinComponent.scss";
import MainImg from "../../assets/images/new-images/MB100.png";

function MediumBinComponent(props) {
  return (
    <div className="page-component">
      <div>
      <iframe src="https://app.vectary.com/p/0ZqqAJIy4PCycf9L2MkAbz"></iframe>
      </div>
      <div>
        <h2 className="subhead">Features</h2>
        <ul className="accessories-list">
        <li>12 Gauge steel bin body</li>
          <li>14 Gauge steel lid and hatch</li>
          <li>Lifting studs</li>
          <li>
          Versatile lid with the ability of being secured at either 110 degrees or 270 degrees for easy access.
          </li>
          <li>Brackets for installing dump pins</li>
          <li>Handles to safely open and close the lid and hatch</li>
          <li>
          Hatch access for disposing of UCO 
          </li>
          <li>
          Screen manufactured from 5/16” wire rod, spacing is 2” on centre, available in sliding or static configurations  
          </li>
          <li>Single point drop in pin lock housing located centre of the bin combined with lockable hatch </li>
          <li>Fork lift slots for easy maneuverability</li>
          <li>Powder coating, custom client logo and labeling</li>
        </ul>

        <div className="spacer"></div>

        <h4 className="small-subhead">Optional Features</h4>
        <ul className="optional-list">
          <li>Dump pins </li>
          <li>Bin dollies for easy onsite movability</li>
          <li>Custom screens are available as per client requests</li>
        </ul>
        <div className="spacer"></div>

<p className="body-text">
  The screen is located below the level of the bin body to reduce the
  chances of overfilling the bin and causing a spill. This detailed
  feature will also keep your bins looking clean over time. 
  <br /><br />
  Our bins are designed to be secured but also provide faster access to the collection company in order to increase UCO collection efficiency
</p>
        {/* <table border={1} id="info-table">
          <thead>
            <tr>
              <th>ITEM NO. </th>
              <th>PART NUMBER </th>
              <th>DESCRIPTION </th>
              <th>QTY.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>LARGE BASE FRAME</td>
              <td>12 GAUGE MILD STEEL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>2</td>
              <td>LONG C-CHANNEL -LB 2</td>
              <td>12 GAUGE MILD STEEL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>3</td>
              <td>LONG C-CHANNEL -LB</td>
              <td>12 GAUGE MILD STEEL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>4</td>
              <td>SHORT C-CHANNEL-LB</td>
              <td>12 GAUGE MILD STEEL</td>
              <td>2</td>
            </tr>
            <tr>
              <td>5</td>
              <td>FOKRLIFT POCKETS - LB</td>
              <td>8x2x0.188 HSS</td>
              <td>2</td>
            </tr>
            <tr>
              <td>6</td>
              <td>LIFTING ROD</td>
              <td>STEEL</td>
              <td>2</td>
            </tr>
            <tr>
              <td>7</td>
              <td>LOWER LUG BRACKET</td>
              <td>STEEL, WELD ON</td>
              <td>4</td>
            </tr>
            <tr>
              <td>8</td>
              <td>CHAIN CONNECTION STAPLE</td>
              <td>STEEL, WELD ON</td>
              <td>2</td>
            </tr>
            <tr>
              <td>9</td>
              <td>LARGE TOP LID</td>
              <td>14 GAUGE MILD STEEL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>10</td>
              <td>HATCH COVER</td>
              <td>14 GAUGE MILD STEEL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>11</td>
              <td>HATCH HINGE</td>
              <td>METAL HINGE</td>
              <td>2</td>
            </tr>
            <tr>
              <td>12</td>
              <td>HATCH HANDLE</td>
              <td>STEEL HANDLE, WELDED ON</td>
              <td>3</td>
            </tr>
            <tr>
              <td>13</td>
              <td>RETAINING CHAIN</td>
              <td>METAL CHAIN, WELDED ON</td>
              <td>3</td>
            </tr>
            <tr>
              <td>14</td>
              <td>HATCH COLLAR</td>
              <td>14 GAUGE MILD STEEL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>15</td>
              <td>FLATBAR</td>
              <td>0.125" FLAT BAR</td>
              <td>4</td>
            </tr>
            <tr>
              <td>16</td>
              <td>STEEL WIRE</td>
              <td>5/16" WIRE</td>
              <td>16</td>
            </tr>
            <tr>
              <td>17</td>
              <td>LID LOCK</td>
              <td>MILD STEEL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>18</td>
              <td>LID LOCK 2</td>
              <td>MILD STEEL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>19</td>
              <td>LID LOCKING PIN</td>
              <td>STEEL PIN</td>
              <td>1</td>
            </tr>
            <tr>
              <td>20</td>
              <td>LID HINGE</td>
              <td>METAL HINGE</td>
              <td>8</td>
            </tr>
            <tr>
              <td>21</td>
              <td>HINGE PLATE</td>
              <td>MILD STEEL</td>
              <td>4</td>
            </tr>
            <tr>
              <td>22</td>
              <td>LARGE SIDE FRAME TAPER</td>
              <td>12 GAUGE SHEET METAL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>23</td>
              <td>LARGE SIDE FRAME TAPER 2</td>
              <td>12 GAUGE SHEET METAL</td>
              <td>1</td>
            </tr>
            <tr>
              <td>24</td>
              <td>SIDE FRAME TOP CAP</td>
              <td>12 GAUGE MILD STEEL</td>
              <td>2</td>
            </tr>
            <tr>
              <td>25</td>
              <td>LOWER LIFTING LUG</td>
              <td>STEEL</td>
              <td>2</td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </div>
  );
}

export default MediumBinComponent;
