import React from "react";
import "./whyChooseSigma.scss";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

function WhyChooseSigma(props) {
  const choosePoints = [
    {
      headline: "Unparalleled Quality:",
      text: "Our products are built to last, ensuring durability and reliability, even in the toughest environmental conditions.",
    },
    {
      headline: "Competitive Pricing",
      text: "We believe in fair pricing without compromising on quality. Enjoy cost-effective solutions that help boost your profits.",
    },
    {
      headline: "Partnership & Support",
      text: "We're not just suppliers; we're partners in your success. Count on us for exceptional support and assistance.",
    },
  ];

  return (
    <div className="whyChooseSection">
      <h3 className="subhead text-centre">
        Why Choose <span className="green-text">SIGMA Industries?</span>
      </h3>
      <div className="whyChooseGrid">
        {choosePoints.map(function (data) {
          return (
            <div>
              <IoMdCheckmarkCircleOutline
                style={{ fontSize: "30pt", color: "#1a4a5b", margin: "20px" }}
              />
              <h4 className="small-subhead text-centre">{data.headline}</h4>
              <p className="body-text text-centre">{data.text}</p>
            </div>
          );
        })}
      </div>
      <div className="blue-divider"></div>
      <div className="text-container">
        <p className="body-text text-centre">
          Explore our range of used cooking oil containment solutions and how
          SIGMA Industries can help you achieve your businesses goals while
          optimizing your collection process. Contact us today to get started.
        </p>
      </div>
      <a href="#contactForm">
        <button className="blue-btn">Get in Touch</button>
      </a>
    </div>
  );
}

export default WhyChooseSigma;
