import React from "react";
import "./aboutUs.scss";
import BinImg from "../../assets/images/about.jpg";

import Customer from "../../assets/icons/Customer.svg";
import Safety from "../../assets/icons/Safety.svg";
import Sustainability from "../../assets/icons/Sustainability.svg";
import Quality from "../../assets/icons/Quality.svg";

function AboutUs({id}) {
  const ourValues = [
    {
      icon: Customer,
      headline: "Customer-Centric Approach",
      text: "Our customers are at the heart of everything we do. We believe in building strong, long lasting relationships by going the extra mile to meet your needs and exceed your expectations.",
    },
    {
      icon: Quality,
      headline: "Quality Assurance",
      text: "Quality is non-negotiable at SIGMA Industries. We take pride in our state of the art manufacturing process and stringent quality control measures to ensure that our products meet the highest standards.",
    },
    {
      icon: Safety,
      headline: "Safety",
      text: "Safety is a critical value for our company. We are dedicated to maintaining a safe working environment for all of our employees while at the same time producing steel products that meet the highest safety standards.",
    },
    {
      icon: Sustainability,
      headline: "Sustainability",
      text: "We are passionate about environmental responsibility. By supporting the collection and recycling of used cooking oil, we contribute to a greener, more sustainable future for our planet.",
    },
  ];

  return (
    <div className="about-us-container-outer" id={id}>
      <div className="about-us-container">
        <div>
          <h3 className="subhead">
            Our <span className="green-text">Mission</span>
          </h3>
          <p className="body-text">
            At SIGMA Industries, our mission is clear: to deliver our valued
            customers a top-tier product and service. Our mission as a leading
            manufacturer and supplier of steel products is to provide superior
            products that set the industry standard for quality, durability, and
            performance. We understand that our success is directly connected to
            the success of our customers and as such, we are dedicated to
            serving our customers with excellence by offering a wide range of
            innovative, reliable and custom solutions tailored to their specific
            needs. 
            <br />
            <br /> 
            Through cutting-edge technology, environmentally responsible
            practices, and unwavering commitment to customer satisfaction, we
            aim to be the preferred partner for businesses across the used
            cooking oil collection and renewables industry. Our mission is not
            only to meet but to exceed the expectations of our customers, all
            while contributing to a sustainable future.
          </p>
        </div>
        <div>
          <img src={BinImg} className="about-us-img" />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
